
import React from 'react';


function Home() {

    return (
      <div class="row">
      <div class="column1">
        <h2>
          <img 
          src = "https://media.istockphoto.com/photos/mexican-rice-and-chorizo-sausage-wrap-picture-id1313361282?k=20&m=1313361282&s=612x612&w=0&h=TEt2xdGkFu7jXn3l8pmg743NSQujZuhT14B0MR_3B1o=" 
          alt = 'beautiful burrito'
          class="responsive"></img>
        </h2>
      </div>
      <div class="column2">
        <h2>Welcome!</h2>
        <p></p>
        <p>
          Since 1995 we have been dishing up the biggest and most delicious burritos! Made with
          our family recipes, we strive to make the best burritos in town with the finest ingredients.
          If you like burritos, we know you'll love Burrito Builders! 
        </p>

       
  




  
      </div>
      </div>
    );
    }
  export default Home;