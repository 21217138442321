import React from 'react';


function Complete() {

    return (
       
        <div class="complete-container">

            <div class='complete-card'>
                <div class='complete-card-title'>Order Complete!</div>
                <div class='complete-image'></div>
                <div class='complete-card-title'>Have a Burritoful Day!</div>
            </div>

        </div>
        
    );
  }
  
  export default Complete;